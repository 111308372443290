import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import ChevronLeft from '../assets/chevron-left.svg'

import './team.scss'

const Team = () => {
    const data = useStaticQuery(graphql`
        query {
            allBoardMembersJson {
                nodes {
                    name
                    role
                    description
                    src {
                        childImageSharp {
                            fluid(maxWidth: 700) {
                                src
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout
            title='Board of directors'
            body={[
                'The Ministry\'s board of directors have a combined 30+ years of experience in the fields of culture, music, tech and working with local governments and organisations. With our diverse backgrounds, we\'re able to quickly build out ideas by connecting our various skill sets and networks of people.',
                'We surround ourselves with many experts in the various cultural fields, and pride ourselves on having built a national and international community of people we can bring onto our projects to make them succeed.'
            ]}
            currentlyActiveTab='team'
        >
            <div className='team'>
                {data.allBoardMembersJson.nodes.map((node) => (
                    <div className='team__member' key={node.name.replace(' ', '-')}>
                        <div className="team__member__content">
                            <h3 className="team__member__content__name">{node.name}</h3>
                            <div className="team__member__content__role">{node.role}</div>
                        </div>
                        <Img
                            className='team__member__image'
                            fluid={{
                                ...node.src.childImageSharp.fluid,
                                aspectRatio: 1
                            }}
                        />
                        <div className="team__member__description">{node.description}</div>
                        <div className="team__member__arrow">
                            <ChevronLeft/>
                        </div>
                    </div>
                ))}
            </div>
        </Layout>
    )
}
export default Team